import React from "react";
import imgCLose from "../../images/icon/close.svg";

export default function CloseModal({ close }) {
  return (
    <button className="modal__close" title="close" onClick={close}>
      <img src={imgCLose} alt="cross" />
    </button>
  );
}
