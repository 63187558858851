import React, { useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import { CAPTCHA, LOGIN, SIGN_UP, SITE_KEY } from "./consts";

import imgEmail from "../../images/icon/mail.svg";
import imgLock from "../../images/icon/lock-fill.svg";
import imgTg from "../../images/icon/telegram.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import ResetPas from "./ResetPas";
import { getFormInputs } from "../../utils/utils";
import { API_LOGIN } from "../../api/login";
import CusCheckbox from "../mui/CusCheckbox";

export default function SignUpModal({ isOpen, setOpen, type = SIGN_UP, setOpenTypeApprove }) {
  const isLogin = type === LOGIN;
  const isSignUp = type === SIGN_UP;

  const LangToHl = { english: "EN", russian: "RU" };
  const title = isSignUp ? "Become a partner" : "Login to Camsoffer";
  const textButton = isSignUp ? "Create an account" : "Login";
  const { t } = useTranslation();
  const captchaRef = useRef();

  const [isResetPas, setIsResetPas] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorMes, setErrorMes] = useState("");

  const fields = useMemo(() => {
    const tgField = {
      text: "Telegram",
      name: "telegram",
      icon: imgTg,
      type: "text",
      grayWarning: "if you have",
    };
    const GWPasText = "The minimum length is 8 characters";

    const fields = [
      {
        text: "E-mail",
        icon: imgEmail,
        type: "email",
        name: "email",
        grayWarning: "Email is required",
        required: true,
      },
      {
        text: "Password",
        icon: imgLock,
        type: "password",
        name: "password",
        grayWarning: GWPasText,
        dopOptions: { minLength: 8 },
        required: true,
      },
    ];

    if (isSignUp) fields.push(tgField);
    return fields;
  }, [isSignUp]);

  const HL = LangToHl[t("currentLang")] || "EN";

  const closeModal = () => {
    setIsResetPas(false);
    setOpen(false);
    setErrorMes("");
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const children = e.target.querySelectorAll("input");
    const data = getFormInputs(children);
    data.captcha = captchaRef.current.getValue();
    data.login_after_join = true;

    const campaignId = localStorage.getItem("campaign_id");
    if (campaignId) {
      data.campaign_id = +campaignId;
    }

    if (isSignUp) {
      API_LOGIN.register(data)
        .then((e) => {
          console.log(e);
          if (e.data.status || e.data.ok) {
            window.location.href = `/webmaster`;
            return;
          }
          setErrorMes(e.response.data.message);
        })
        .catch((e) => setErrorMes(e.response.data.message))
        .finally(() => setBtnLoading(false));
    }
    if (isLogin) {
      API_LOGIN.login(data)
        .then((e) => {
          console.log(e);
          if (e.data.status || e.data.ok) {
            window.location.href = `/webmaster`;
            return;
          }
          setErrorMes(e.response.data.message);
        })
        .catch((e) => setErrorMes(e.response.data.message))
        .finally(() => setBtnLoading(false));
    }

    captchaRef.current.reset();
  };

  return (
    <Modal
      ariaHideApp={false}
      className={`login-modal`}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <CloseModal close={closeModal} />

      {!isResetPas || isSignUp ? (
        <>
          <h1 className="login-modal__title">{t(title)}</h1>

          <form onSubmit={onFormSubmit} className="login-modal__form">
            {fields?.map((option, indx) => (
              <CusInput onChange={() => setErrorMes("")} key={indx} {...option} />
            ))}

            {!!CAPTCHA?.["protect-login-page"] && (
              <ReCAPTCHA
                ref={captchaRef}
                hl={HL}
                sitekey={SITE_KEY}
                onChange={() => setErrorMes(false)}
              />
            )}

            {isLogin && (
              <button
                type="button"
                className="login-modal__reset-pas"
                onClick={() => setIsResetPas(true)}
              >
                {t("Reset Password")}
              </button>
            )}

            {errorMes && <span className="error-text">{errorMes}</span>}

            {isSignUp && (
              <div className="sign-up__checkbox">
                <CusCheckbox
                  required
                  text={
                    <span>{t("I am over 18 years old or the Age of Majority in my region")}</span>
                  }
                  id={"age"}
                />

                <CusCheckbox
                  required
                  text={
                    <span>
                      {t("I have read, understood, and accept the")}{" "}
                      <button type="button" onClick={() => setOpenTypeApprove("service")}>
                        {t("Agreement")}
                      </button>
                    </span>
                  }
                  id={"agreement"}
                />

                <CusCheckbox
                  required
                  text={
                    <span>
                      {t("I expressly declare the acceptance of")}{" "}
                      <button type="button" onClick={() => setOpenTypeApprove("privacy")}>
                        {t("Privacy Policy")}
                      </button>
                    </span>
                  }
                  id={"politic"}
                />
              </div>
            )}

            <CusButton
              disabled={!!errorMes}
              type="submit"
              loading={btnLoading}
              text={textButton}
              className="login-modal__button"
            />
            {/* <button type="submit" /> */}
          </form>
        </>
      ) : (
        <ResetPas closeModal={closeModal} />
      )}
    </Modal>
  );
}
