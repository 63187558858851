import React from "react";
import "../cookie/cookie.scss";
import PolicyContentRU from "./PolicyContentRU";
import PolicyContentEN from "./PolicyContentEN";
import { useLocalStorage } from "usehooks-ts";
import { getLang } from "../../../utils/utils";

export default function Policy() {
  const chosenLang = getLang();
  const [selectLang] = useLocalStorage("lang", chosenLang);

  return (
    <section className="cookie">
      {selectLang === "ru" ? <PolicyContentRU /> : <PolicyContentEN />}
    </section>
  );
}
