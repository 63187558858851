import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";

import imgEmail from "../../images/icon/mail.svg";
import { API_LOGIN } from "../../api/login";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA, SITE_KEY } from "./consts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPas({ closeModal }) {
  const LangToHl = { english: "EN", russian: "RU" };
  const { t } = useTranslation();
  const HL = LangToHl[t("currentLang")] || "EN";
  const [btnLoading, setBtnLoading] = useState(false);

  const emailRef = useRef();
  const captchaRef = useRef();

  return (
    <>
      <div className="reset-password">
        <h1 className="reset-password__title">{t("Password Recovery")}</h1>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setBtnLoading(true);
            API_LOGIN.resetPas({
              email: emailRef.current,
              captcha: captchaRef.current.getValue(),
            })
              .then((e) => {
                if (e.data.ok) {
                  toast(e.data.message || t("your email has been successfully confirmed"), {
                    style: { background: "#008000", color: "#fff" },
                  });
                  closeModal();
                  return;
                }
                toast(e.data.message || "An error has occurred", {
                  style: { background: "#f00", color: "#fff" },
                });
              })
              .catch((e) => {
                toast(e.data.message || "An error has occurred", {
                  style: { background: "#f00", color: "#fff" },
                });
              })
              .finally(() => {
                setBtnLoading(false);
              });
          }}
          className="reset-password__content"
        >
          <CusInput
            required
            icon={imgEmail}
            text={"Type your email"}
            onChange={(e) => (emailRef.current = e.target.value)}
          />
          {!!CAPTCHA["protect-reset-password-page"] && (
            <ReCAPTCHA ref={captchaRef} hl={HL} sitekey={SITE_KEY} />
          )}
          <CusButton
            type="submit"
            loading={btnLoading}
            className="cus-button__outline"
            text={"Get code"}
          />
          <p>
            {t(
              "After clicking on the 'Get code' button, an email with a confirmation code will be sent to the entered E-mail. If the letter did not come, check the 'SPAM' folder."
            )}
          </p>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
