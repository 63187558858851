import React from "react";
import AffiliatesByCard from "./AffiliatesByCard";
import { useTranslation } from "react-i18next";

export default function Affiliates() {
  const { t } = useTranslation();

  const statisticByers = [
    {
      title: "BY THE DAY",
      date: "(04.08.2022)",
      type: "day",
      content: [
        {
          name: "FlowerPower",
          sum: "$24,025.04",
        },
        {
          name: "Webmaster",
          sum: "$1,552.38",
        },
        {
          name: "Webmaster#",
          sum: "$1,081.94 ",
        },
        {
          name: "Zaza",
          sum: "$1,061.63 ",
        },
        {
          name: "Webmaster#2",
          sum: "$626.66 ",
        },
        {
          name: "Anacleto Semilha",
          sum: "$610.44 ",
        },
        {
          name: "Webmaster#3",
          sum: "$573.68 ",
        },
        {
          name: "Webmaster#4",
          sum: "$442.22",
        },
        {
          name: "Webmaster5",
          sum: " $434.14 ",
        },
        {
          name: "Webmaster6",
          sum: "$403.49",
        },
      ],
    },
    {
      title: "BY THE WEEK",
      date: "(04.08.2022)",
      type: "week",
      content: [
        {
          name: "FlowerPower",
          sum: "$24,025.04",
        },
        {
          name: "Webmaster",
          sum: "$1,552.38",
        },
        {
          name: "Webmaster#",
          sum: "$1,081.94 ",
        },
        {
          name: "Zaza",
          sum: "$1,061.63 ",
        },
        {
          name: "Webmaster#2",
          sum: "$626.66 ",
        },
        {
          name: "Anacleto Semilha",
          sum: "$610.44 ",
        },
        {
          name: "Webmaster#3",
          sum: "$573.68 ",
        },
        {
          name: "Webmaster#4",
          sum: "$442.22",
        },
        {
          name: "Webmaster5",
          sum: " $434.14 ",
        },
        {
          name: "Webmaster6",
          sum: "$403.49",
        },
      ],
    },
  ];

  return (
    <div className="home__affiliates">
      <div className="home__affiliates__content">
        <h2 className="home__affiliates__title">{t("TOP AFFILIATES")}</h2>
        <div className="home__affiliates__cards">
          {statisticByers.map((statistic, indx) => (
            <AffiliatesByCard
              statistic={statistic}
              key={indx}
              className={`home__affiliates__card-by__${statistic.type}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
