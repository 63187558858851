import React, { useState } from "react";
import CusButton from "../mui/CusButton";
import ApproveModal from "./ApproveModal";
import SignUpModal from "../common/SignUpModal";
import { SIGN_UP } from "../common/consts";
import { useTranslation } from "react-i18next";

const termsOfService = `Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.
  Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.
  Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.`;

const approveTexts = {
  service: { title: "Terms of service", text: termsOfService + termsOfService },
  privacy: { title: "Privacy polices", text: termsOfService },
};
export default function Welcome() {
  const [openTypeApprove, setOpenTypeApprove] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const { t } = useTranslation();

  const titleModal = approveTexts[openTypeApprove]?.title;
  const textModal = approveTexts[openTypeApprove]?.text;
  const percents = [
    {
      percent: "25%",
      subtitle: "from visitors",
      text: "Refer visitors and get 25% of all their spending on a webcam site",
    },
    {
      percent: "5%",
      subtitle: "from models/ performers",
      text: "Refer broadcasters and earn 5% of all their earnings",
    },
    {
      percent: "10%",
      subtitle: "from webmasters/ partners",
      text: "Refer traffic partners and earn 10% of all their earnings",
    },
  ];

  return (
    <>
      <div className="home__welcome">
        <div className="home__welcome__content">
          <div className="home__welcome__left">
            <div className="home__welcome__left__webmaster">
              <p>
                {t("Welcome to")}
                <br />
                <span className="home__welcome__left__webmaster__title">{t("Adult Sex Cams")}</span>
                <br />
                {t("Affiliate Program for")}
              </p>
              <h1 className="home__welcome__left__webmaster__title">{t("Webmasters")}</h1>
            </div>
            <div className="home__welcome__left__text-camhafs">
              <p>
                {t("CumHafs adult affiliate marketing offer")}
                <br />
                {t("gives you the opportunity to earn money by sending visitors")}{" "}
                <a href={"http://richCams.com"} target="_blank" rel="noreferrer">
                  {t("to RichCams.com")}
                </a>
              </p>
            </div>
            <div className="home__welcome__left__money">
              <h3>{t("Make money on cams")}</h3>
              <CusButton text="Sign up now" onClick={() => setOpenLoginModal(SIGN_UP)} />
              <span className="home__welcome__left__money__politic">
                {t("By Sign up, you agree to")}{" "}
                <button onClick={(e) => setOpenTypeApprove("service")}>{t("Terms")}</button>{" "}
                {t("and")}{" "}
                <button onClick={(e) => setOpenTypeApprove("privacy")}>
                  {t("Privacy policy")}
                </button>
              </span>
            </div>
          </div>
          <div className="home__welcome__right">
            <div className="home__welcome__right__top">
              <h2>{t("AFFILIATE OFFER")}</h2>
              <span>{t("Revenue share lifetime")}</span>
            </div>
            <div className="home__welcome__right__percents">
              {percents.map((percent, indx) => (
                <div key={indx} className="home__welcome__percent">
                  <h2>{percent.percent}</h2>
                  <h4>{t(percent.subtitle)}</h4>
                  <span>{t(percent.text)}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ApproveModal
        setOpen={setOpenTypeApprove}
        isOpen={!!openTypeApprove}
        title={titleModal}
        text={textModal}
        type={openTypeApprove}
      />

      <SignUpModal isOpen={!!openLoginModal} type={openLoginModal} setOpen={setOpenLoginModal} />
    </>
  );
}
