import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

export default function CusButton({
  img,
  text,
  className = "",
  onClick,
  loading,
  disabled,
  type = "button",
}) {
  const { t } = useTranslation();

  const isLoading = loading ? "loading" : "";

  return (
    <button
      type={type}
      className={`custom-button ${isLoading} ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <>
        {!!img && <img src={img} alt="login"></img>}
        <span>{t(text)}</span>
        {loading && <Spinner />}
      </>
    </button>
  );
}
