import React from "react";

export default function PolicyContentEN() {
  return (
    <>
      <h1>Privacy Policy</h1>
      <pre>
        {`Is the publisher and operator (referred to as the “Company”, “we”, “our” or “us”) of the RichCams website, namely RichCams.com (referred to as the “Website”).

The Company is committed to protecting your privacy and handling your personal data in an open and transparent manner. The personal data that we collect and process will vary depending on how you use the Website.`}
      </pre>

      <h2>This privacy policy:</h2>
      <pre>{`provides an overview of how the Company collects and processes your personal data and tells you about your rights under the EU General Data Protection Regulation (“GDPR”);
is directed to individuals who are: 
(a) visitors of the Website (referred to in this privacy policy as “Users”); 
(b) individuals who have registered as members of the Website by creating their own account (referred to in this privacy policy as “Members”); 
(c) individuals who have registered as models (referred to in this privacy policy as “Models”).
(d) natural and/or legal persons who have created an Account with the Websites in order to participate in the Affiliate Program of the Websites by creating their own account (referred to in this privacy policy as “Affiliate Partners”).
Users, Members, Models and Affiliate Partners must be aged eighteen (18) years and over or must have attained the age of majority in their respective jurisdiction in order to be able to use the Website. All persons who do not meet these criteria are strictly forbidden from accessing, viewing the contents of, or otherwise joining the Website. We do not knowingly seek or collect any personal information from persons who have not attained the age of eighteen (18).
For the purposes of this privacy policy:
when we refer to “personal data” or “personal information” we mean data which identifies or may identify you and which may include, for instance, your email or IP address;
when we refer to “processing” we mean the handling of your personal data by us, including collecting, protecting and storing your personal data; and
when we refer to “sensitive data” we mean personal data which may reveal information about racial or ethnic origin, political opinions, religious beliefs, trade union activities, physical or mental health, sexual life, genetic or biometric data.
`}</pre>

      <h2>1. How we collect your personal data</h2>
      <pre>{`We obtain your personal data mainly through any information you provide directly to us through our Website. Sometimes your personal data can also be shared with us, if for example you use an authentication system to Log in. Below is a list of ways we collect your personal data:
when you visit and/or use our Website;
when you complete our registration form;
when you log in to our website using an authentication system;
when you post materials or upload content;
when you participate in chat sessions;
when you take part in surveys or report a problem with our Website; and/or
when you contact with us via email, post or other ways of communication.
when you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this privacy policy.
It does not apply to information collected by:
us offline or through any other means, including on any other website operated by us or any third party (including our affiliates and subsidiaries); or
any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.`}</pre>

      <h2>2. If you fail to provide personal data</h2>

      <pre>{`Users/Members
Where we need to collect and process your personal data in order to provide you with our services and you fail to provide that data when requested, we may not be able to provide you with access to our Website and/or the services we offer. In this case, we may have to suspend your access to the Website or, if you are a Member, prohibit any access to non-public areas of the Website or restrict the operation of your account and the services we provide to you.

Models
Where we need to collect and process your personal data in order to enable you to use our Website as a Model and you fail to provide that data when requested, we will not be able to provide you with final approval to appear on the Website or, if such approval was provided to you in the past, we may withdraw such approval and suspend the operation of your account.
`}</pre>

      <h2>3. Personal data that we collect and process</h2>
      <pre>{`We will collect different categories of personal data from you depending on your level of interaction with our Website. We will limit the collection and processing of information to information necessary to achieve one or more of our legitimate purposes as identified in this privacy policy.

(a) Personal data we collect and process from Users
Technical Data, including your Internet Protocol (IP) address and associated country, your browser type and version, time zone setting and location, smart device information, mobile phone network information, operating system and platform and other technology on the devices you use to access this Website;
Cookies including web beacons. For more information on the cookies we collect, please visit out Cookies Policy.
Usage Data, including your communication preferences and information about how you use our Website including the services you viewed or searched for, page response times, download errors, length and number of visits and page interaction information (such as scrolling, clicks and mouse-overs) as well as the links you clicked on or used. To learn more about our use of cookies or similar technology, please check our Cookies Policy;
User Submitted Data, including data collected for a specific function, for instance a contest or a survey;
Communications and correspondence, including any correspondence between you and us and any other communication between you and anyone else on the Website, including customer service requests, emails and live chats.
Consents, including any permissions, consents or preferences that you give to the Website.

(b) Personal data we collect and process from Members
All personal data we collect from Users, set out in paragraph 3(a) above;
Identity and Contact Data, including username or similar identifier, password, full name and email address. We may also collect your photo or video, if such is uploaded to your profile;
Usage Data, including data about which products you use and purchase, all orders you place on the Website and all metrics and measurements regarding your orders, bids, advertisements, campaigns and any other of your activities on the Website.
User Submitted Data, including data collected at your discretion for a specific function, for instance your username and password, your interests, preferences, feedback and survey responses, or any text, images, videos, blog posts, forum comments or other content or media you choose to upload on the Website.
Financial and Transactional Information, including bank account, direct debit data, billing address and fund transfers and other details of products and services you have purchased or received from us; and
Marketing Data, including preferences in receiving marketing from us, our affiliates and third-parties.

(c) Personal data we collect and process from Models
All personal data we collect from Members, set out in paragraph 3(b) above;
Identity and Contact Data, including stage name, your date of birth, your address, a valid government identification number (such as your identity or passport number) and a photo of yourself holding your identification document.
User Submitted Data, including your likeness, performance, acts, poses and play, any musical, instrumental or sound effects and any dialogues you have with the users.
Data generated during your use of the Website, including your performer ID and any screenshots we take from your video streams.

(d) Personal data we collect and process from Studio Operators.
All personal data we collect from Members, set out in paragraph 3(b) above; and
Identity Data, including the name of the ultimate beneficial owner of a Studio Operator which is a corporate entity or of other officers of the Studio Operator which are disclosed to us from time to time. Where the Studio Operator is a natural person, we may also collect his identity document, but only if it is voluntarily uploaded on the Studio Operator’s account.
We may also collect, use and share aggregated data such as statistical or demographic data for the purpose of customizing our marketing efforts or the use of our Website. Aggregated data may be derived from your personal data but is not considered personal data as this data does not directly or indirectly reveal your identity. For instance, we may aggregate your Usage Data to calculate the percentage of users accessing a specific Website feature, to generate statistics about our users, to calculate the percentage of users accessing a specific Website feature, to calculate ad impressions served or clicked on, or to publish visitor demographics. If we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we will treat the combined data as personal data which shall be used in accordance with this privacy policy.
`}</pre>

      <h2>4. Sensitive data</h2>
      <pre>{`If you are a Users or Member, we may process sensitive data in relation to your ethnic origin or your sexual orientation, provided that we have first obtained your explicit consent to do so. We will never request that you disclose your sexual orientation to us, however, we may infer such information from your use of the Website.

If you are a Model, we will process sensitive data in relation to your health or your racial or ethnic origin, provided that we have first obtained your explicit consent to do so. Please note if you refuse to provide your consent, or if you withdraw your consent to us collecting and processing such data, you may not be able to use, or continue using, your account on the Website.
`}</pre>

      <h2>5. Automated personal data collection</h2>
      <pre>{`As you navigate through and interact with our Website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect these data by using cookies and other similar technologies. We may also use these technologies to collect information about your online activities across third-party websites. Please see our Cookies Policy for further details.
`}</pre>

      <h2>6. Why we need your personal data and how we use it</h2>
      <pre>{`We will only use and share your information where it is necessary for us to provide you with our lawful services in accordance with this privacy policy. We would like to ensure that you fully understand why your information may be used. We will only use your personal data when the applicable local law allows us to.

We will most commonly use your personal data in the following circumstances:
a) where we need to perform our services to you, as necessary in accordance with our Website’s Terms of Use;
b) where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests;
c) where we need to comply with a legal or regulatory obligation; and/or
d) where you have given us your consent.
Below, we describe these circumstances in further detail.
(a) Performance of our services to you
(1) Provision of services

We may collect and process your personal data where it is necessary in order to present our Website and its contents to you, including any interactive features on our Website and to provide you with information, products or services that you request from us.

Please note that if you do not agree to provide us with the requested information, it may not be possible for us to continue to operate your account and/or provide you with the services we provide to our Members.

(2) Customer management (Members Only)

We may collect and process your personal data where it is necessary in order to manage a Member’s account, to provide customer support and notices to Members about their account and/or subscription, including expiration and renewal notices, and inform you about any notices about changes to our Website or any products or services we offer or provide through it.

(3) Model and Studio Operator Management

We may collect and process your personal data where it is necessary to administer our relationship with you, manage your account and provide you with support about your account, including expiration and renewal notices, and inform you about any changes to the Website that may affect the services that you provide to us.

(b) Legitimate interests
(1) Customization of content (Members Only)

We may collect and process your personal data where it is necessary in order to perform research and analysis about your use or, or interest in, our Website content and/or services, so as to develop and display content and advertising tailored to your interests on our Website.

(2) Analytics

We may collect and process your personal data where it is necessary in order to determine whether users of the Website are unique, or whether the same user is using the Website on multiple occasions, and to monitor aggregate metrics such as total number of visitors, pages viewed.

(3) Functionality and security

We may collect and process your personal data where it is necessary to diagnose or fix technology problems, and to detect, prevent, and respond to actual or potential fraud, illegal activities, or intellectual property infringement.

(4) Other legitimate interests

In addition to the above, we may process your data, to the extent required for our business needs in order to:
1) develop new services on our Website as well as analyze and target new markets;
2) protect our legal rights and interests (including legal claims and preparing our defense in litigation);
3) monitor, maintain and improve internal business processes, information and data, technology and communication solutions and services;
4) ensure network information security, including monitoring Users and Members access to our information technology for the purpose of preventing cyber-attacks, unauthorized use of Website and prevention or detection of crime;
5) send you relevant marketing information (including details of other services provided by us which we believe may be of interest to you), but only where you are Members of the Website;
6) monitor the performance and effectiveness of our services and the Website; and
7) assess the quality of our customer services.
(c) For compliance with legal obligations
When you visit and/or use and/or register in our Website (and throughout your relationship with us), we are required to comply with certain legal and regulatory obligations which may involve the processing of your personal data. This may include processing to:
1) perform checks and comply with laws relating to fraud;
2) identify other infringements of our Terms of Use; and
3) manage contentious regulatory matters, investigations and litigation.
(d) You have provided your consent
We will ask for your consent when we wish to provide marketing information to you in relation to our services which we believe may be of interest and of benefit to you, but only where you are a User.

We will also ask for your consent when we collect sensitive data for you, where you are a Member or a Model, as described in this privacy policy.

You have the right to revoke your consent at any time. However, any processing of personal data prior to the receipt of your revocation will not be affected.
`}</pre>

      <h2>7. Who we share your personal data with</h2>
      <pre>{`It is our policy not to use or share personal data about you in ways unrelated to those described in this privacy policy without also providing you with an opportunity to opt out or otherwise object to such unrelated uses. However, we may disclose personal data about you, or information regarding your use of the services or the Website to third parties for any of the reasons set out hereinabove, or if we are legally required to do so, or if we are authorized under our contractual and statutory obligations. When we disclose your personal data to service providers, we will ensure that they are bound by a contract requiring them to comply with their GDPR obligations. Under the circumstances referred to above, recipients of personal data may be, for example:
(a) members of our corporate group which operate partner websites, to the extent necessary for the purposes set out in this privacy policy and to enable you to have access to certain features;
(b) to our authorized service providers that perform certain services on our behalf, such as Verotel, CCBill. These services may include fulfilling orders, processing credit card payments, risk and fraud detection and mitigation, providing customer service, performing business and sales analysis, customization of content, analytics, security, supporting our Website functionality, and supporting contests, surveys and other features offered through our Website. These service providers may have access to personal data needed to perform their functions but are not permitted to share or use such information for any other purposes.
(c) governmental and regulatory bodies, including law enforcement authorities, in connection with enquiries, proceedings or investigations by such parties or in order to enable the Company to comply with its legal and regulatory requirements. We specifically reserve the right to disclose any and all information to law enforcement in the event that a crime is committed or suspected or if we are compelled to do so by lawful criminal, civil or administrative process, discovery requests, subpoenas, court orders or writs, to enforce applicable terms of use, including investigation of potential violations thereof, to detect, prevent or otherwise address security or technical issues, protect against harm to the rights, property or safety of our company, our users, our employees, or others; or to maintain and protect the security and integrity of our Website or infrastructure. In such cases, we may raise or waive any legal objection or right available to us, in our sole discretion.
(d) buyers or other legal successors in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by us about our Website Members, Users, Models and Studio Operators is among the assets transferred. Should such a sale or transfer occur, we will use reasonable efforts to ensure that the entity to which we transfer your personal data uses it in an manner that is consistent with this privacy policy; and/or
(e) legal or other professional consultants.
We may also disclose aggregated information about our users, and information that does not identify any individual, without restriction. We also may share aggregated information with third-parties for conducting general business analysis. This information does not contain any personal data and may be used to develop content and services that we hope you and other users will find of interest and to target content and advertising
`}</pre>

      <h2>
        Please note that we have never sold and will never sell your personal data to third parties
      </h2>

      <h2>8. Financial Information</h2>

      <pre>{`Financial information (including personal information) that you have provided to us will only be shared with our third-party processors in order to initiate and complete any orders placed on your account. All credit card transactions and such are processed with industry standard encryption through third party processors who only use your financial information and personal information for that purpose. All financial data and related personal information will not be shared by us with third parties except with your authorization or when necessary to carry out all and any transactions requested by you with the understanding that such transactions may be subject to rules, terms, conditions and policies of a third party. All such information provided to a third party is subject to their terms and conditions.
`}</pre>

      <h2>9. Members’ and Models’ Profile</h2>

      <pre>
        Members and/or Models should be aware that all information directly provided on the Website
        when they create an account is public and can be viewed by all other Members and Users of
        the Website, unless otherwise stated. Even if a Member’s and/or Model’s profile does not
        contain any personal information, there is a possibility of identification by others through
        other information on the Member’s and/or Model’s profile. Accordingly, Members and/or Models
        should understand that they are in control of all of the information they upload on the
        Website and/or display on their profile and are fully responsible for any consequences
        arising out of such information.
      </pre>

      <h2>10. User Submissions</h2>

      <pre>
        We may provide areas on our Website where you can submit or post information about yourself
        and others and communicate with others, upload content (e.g., pictures, videos, audio files,
        etc.), and post comments or reviews of content found on the Website. Such submissions and or
        postings are governed by the Terms of Use of the Website. If you use a forum, blog, user
        commenting features, community or other interactive sharing or social features that may be
        offered through our Website, you should be aware that any personal information you submit,
        display, or publish there is considered publicly available and can be read, collected, used,
        and disclosed by other users of those features, by us, and other third parties without
        restriction, except to the extent limited access features are available, if any. In
        addition, such submissions and or postings may appear on other websites or when searches are
        executed on the subject of your posting. If you submit and or post your email address, you
        may receive unsolicited messages. We cannot control who reads your submission and or posting
        or what other users may do with the information you voluntarily submit and or post, so we
        encourage you to exercise discretion and caution with respect to your personal information.
        Once you have submitted and or posted information, you may not be able to edit or delete
        such information. To request removal of your personal information which you have posted or
        submitted in the manner stated above, send us an e-mail. In some cases, we may not be able
        to remove your personal information, in which case we will let you know if we are unable to
        do so and why.
      </pre>

      <h2>11. Security of your personal data</h2>

      <pre>{`We take appropriate security measures (including physical, electronic and procedural measures) to safeguard your personal data from unauthorized access and disclosure. For instance, only authorized employees are permitted to access personal data, and they may do so only for permitted business functions. In addition, we use encryption in the transmission of your personal data between your system and ours, and we use firewalls to help prevent unauthorized persons from gaining access to your personal data. However, please be advised that we cannot fully eliminate security risks associated with the storage and transmission of personal data as we cannot guarantee that our security measures will prevent third-party hackers from illegally obtaining this information.

You are responsible for maintaining the secrecy of your unique password and account information at all times. We are not responsible for circumventions of any privacy settings or security measures contained on the Website.
`}</pre>

      <h2>12. Information we transfer</h2>
      <pre>{`Some of our external third parties are based outside the European Economic Area (“EEA”) so the processing of your personal data may involve a transfer of data outside the EEA.

Whenever we transfer your data outside the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
a) We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission.
b) Where we use service providers outside the EEA, we may use specific contracts approved by the European Commission which give personal data the same protection it has in the European Union.
c) Where we use providers based in the US, we may transfer data to them if they are part of the EU-US Privacy Shield, which requires them to provide similar protection to personal data shared between the European Union and the US.
We may additionally, in rare occasions, transfer your personal data to a party outside the EEA where we have your prior explicit consent to do so or where such transfer is necessary for the provision of our services to you.
`}</pre>

      <h2>13. Marketing information</h2>
      <pre>{`We may process your personal data so as to inform you about existing and/or future services that may be of interest to you by sending you periodic announcements with details on existing and/or new services and/or programs.

We can only use your personal data to promote our services to you if we have your explicit consent to do so, or in certain cases such as for example when you are a Member of our Website, if we consider that it is in our legitimate interest to do so. You may also occasionally receive marketing communications from our affiliates, but only provided you have given your explicit consent in receiving such communications.

Even if you inform us that you no longer wish to receive marketing material, you will still receive from us system notices and other information specifically related to your account from time to time.
`}</pre>

      <h2>14. How long we keep your personal data for</h2>
      <pre>{`We will only keep your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect of our relationship with you.

To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.

Where we no longer need to process your personal data for purposes set out in this privacy policy, we will delete your personal data from our systems. However, we may keep your data for longer if we cannot delete it for legal, regulatory or technical reasons. If we do, we will make sure that your privacy is protected and that your data are only used for those purposes. The period for which we keep your personal information that is necessary for compliance and legal enforcement purposes varies and depend on the nature of our legal obligations and claims in the individual case.

If you have questions about our data retention practices, please contact us
`}</pre>

      <h2>15. Your data protection rights</h2>
      <pre>{`Subject to local law, you have certain rights regarding the personal data we collect, use or disclose and that is related to you. We want to make sure you are aware of your rights in relation to the personal data we process about you. We have described those rights and the circumstances in which they apply further below.

Right to Access: This enables you to get access or receive a copy of the personal data that we hold about you and to check that we are lawfully processing it.

Right to Data Rectification and Correction: If you believe that any of the information that we hold about you is inaccurate or incomplete, you have a right to request that we correct the inaccurate personal information.

Right to be Forgotten / Erasure: You may request that we delete your personal information if you believe that:
a) we no longer need to process your information for the purposes for which it was provided;
b) we have requested your permission to process your personal information and you wish to withdraw your consent; or
c) we are not using your information in a lawful manner.

Right to object: You have the right to object to processing of your personal data where we are relying on a legitimate interest and there is something about your particular situation which makes you want to object to processing on this ground. If you exercise your right to object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedom or for the establishment, exercise and defense of legal claims.
You also have the right to object where we are processing your personal data for direct marketing purposes. This also includes profiling in as much as it is related to direct marketing.

If you object to processing for direct marketing purposes, then we shall stop the processing of your personal data for such purposes.

Depending on the circumstances, we may need to restrict or cease processing your personal data altogether or, where requested, delete your personal data. Please note that if you object us processing your personal data, we may have to suspend the services we provide to you.

Right to Restriction of Processing: You have the right to request the restriction of processing of your personal data. This enables you to ask us to restrict the processing of your personal data if:
a) it is not accurate; or
b) it has been used unlawfully but you do not want us to delete it; or
c) it is not relevant any more, but you want us to keep it for use in possible legal claims; or
d) you have already asked us to stop using your personal data but you are waiting for us to confirm if we have legitimate grounds to use your personal data.

Please note that if you request us to restrict processing of your personal data, we may have to suspend the services we provide to you.

If we ask for your consent to use your personal data, you can withdraw your consent at any time. Please note that in case of a withdrawal of your consent you may no longer be able to use several functionalities of our Website and our services.

Please note that some requests to delete certain personal data will require the deletion of your account as a Member and/or Model and/or Studio Operator as the provision of Member and/or Model and/or Studio Operator accounts is inextricably linked to the use of certain personal data (e.g. your email address). Also note that it is possible that we require additional information from you in order to verify your authorization to make the request and to honor your request.

To exercise any of your rights, or if you have any other questions about our use of your personal data, please contact us.

We endeavor to address all your requests promptly.

17. How to access or modify your personal data
We remain dedicated to the protection of your data and we want to be clear about the type of information collected, helping you make choices about your privacy. You may:
a) access/modify the information in your profile provided during registration.;
b) if you are a Model, choose whether the photos, videos you have uploaded are visible to everyone or to Members;
c) block cookies, including cookies associated with our services, by activating or deactivating the related cookies on your browser. Please note, however, that if you choose to disable all cookies (including essential cookies) you may not be able to access all or part of our Website. Please see our Cookies Policy for more information.
`}</pre>

      <h2>17. Choices About How We Collect, Use and Disclose Your Personal Information</h2>
      <pre>{`We strive to provide you with choices regarding the personal information you provide to us.
You can choose not to provide us with certain personal information, but that may result in you being unable to use certain features of our Website because such information may be required in order for you to register as a Member or Model; purchase products or services; participate in a contest, promotion, survey; ask a question; or initiate other transactions on our Website.
You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. Please see our Cookies Policy for more information.
When you register on our Website, you may be given a choice as to whether you want to receive email messages, newsletters or both about product updates, improvements, special offers, or containing special distributions of content by us. If you no longer want to receive commercial or promotional emails or newsletters from us, you will need to avail yourself of the unsubscribe mechanism set out in the applicable communication. It may take up to seven days for us to process an opt-out request. We may send you other types of transactional and relationship e-mail communications, such as service announcements, administrative notices, and surveys, without offering you the opportunity to opt out of receiving them. Please note that opting out of receipt of promotional email communications will only affect future activities or communications from us. If we have already provided your information to a third party before you have changed your preferences or updated your information, you may have to change your preferences directly with that third party.
You may opt out of interest-based advertising on mobile devices by activating the “Limit Ad Tracking” or equivalent feature offered by your mobile platform. It will transmit a signal to us and to third-parties that indicates your data should not be used for interest-based advertising.
If you submit personal information, you may delete your account with us at any time. If you delete your account, your Personal Information and any and all other account related information including, but not limited to, user profile data, sharing data and any other data, or content specifically associated with your account will no longer be accessible by you. After deleting your account, if you choose to have an account with us in the future, you will have to sign up for a new account as none of the information you previously provided or saved within your account will have been saved.
`}</pre>

      <h2>18. Our communication with you</h2>
      <pre>
        If you have any questions about this privacy policy or our information-handling practices,
        please contact us by email or Telegram.
      </pre>

      <h2>19. Right to complain</h2>
      <pre>{`If you wish to lodge a complaint about how your personal data is used by the Company, you may contact us by email.

Upon receipt of your complaint, we will investigate it and respond to you within a reasonable time.

We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the collection, use and disclosure of personal data that cannot be resolved by an individual and us.

You also have the right to complain to the data protection supervisory authority of the country in which you reside, provided that such country is a Member State of the European Union or is located within the EEA.
`}</pre>

      <h2>20. Our policy towards minors</h2>
      <pre>
        Our Website is not directed to individuals under the age of eighteen (18) or the applicable
        age of majority in the jurisdiction from which the Website is accessed and we do not
        knowingly collect personal data from minors. If you become aware that your child has
        provided us with personal data, please contact us. If we become aware that a minor has
        provided us with personal data, we will take steps to remove such information and terminate
        that person’s account immediately.
      </pre>

      <h2>21. Third-party links</h2>
      <pre>
        Our Website may include links to third-party websites, plug-ins and applications. Clicking
        on those links or enabling those connections may allow third parties to collect or share
        data about you. We do not control these third-party websites and are not responsible for
        their privacy statements. We specifically disclaim responsibility for their content, privacy
        practices and terms of use, and we make no endorsements or representations about their
        accuracy, content or thoroughness. When you leave our Website, we encourage you to read the
        privacy policy of every website you visit.
      </pre>

      <h2>22. Third Party Login Functionality</h2>
      <pre>{`We may permit users with accounts on certain third-party services, including but not limited to, social networking, search, sharing, and other services delivered by third parties, to bypass the standard applicable registration process. If you connect through an authorized third-party service, you will be able to use your third-party login information to log in to your account or access our content and Website. We are not responsible for the sharing, posting, commenting, or other content and information gathering practices of any third-party services that may be triggered by using these third-party login services. Please review their terms of service and privacy policies carefully before using third party login functionality to access our Website.

By using any third party services to log in to your account or access our Website, you permit us to access, store and use all information related to your third party account that may be accessible to us through the third party service, including through its application programming interface (API), pursuant to this privacy policy
`}</pre>

      <h2>23. California Consumer Privacy Act Notic</h2>
      <pre>{`As of January 1, 2020, the California Consumer Privacy Act of 2018 (“CCPA”) provides California residents (“Consumer(s)”) certain rights with respect to their personal information, as this term is defined under the CCPA. In addition to the rights we state under this policy and subject to the exceptions found under the CCPA, Consumers have the right to:
Opt-out of the sale of their personal information, should we sell their personal information;
Be informed of certain information concerning our collection and use of their personal information;
Request that we delete certain personal information we collected from them;
Appoint an agent to exercise their rights provided for by the CCPA, provided that a duly executed notarized power of attorney is presented and provided that the agent has information deemed sufficient to allow us to verify the identity of the Consumer in question and to locate his/her information in our systems;
Not be subjected to discrimination for the exercise of these rights. We will not deny California residents the use of our service, neither will we provide a different level or quality or services for exercising any of their CCPA rights, unless as permitted under the CCPA.
This Website does not sell nor has it sold in the past 12 months personal information to third parties for monetary or other valuable considerations. We may however disclose certain personal information with third parties, service providers and entities within our corporate group in order to enable them to perform certain services on our behalf and namely to make the Website work properly. Regardless, we respect the California residents’ right to exclude personal information from such sharing arrangements and to thereby opt-out of any future sale of their personal information. If the CCPA is applicable to you and you would like to record such preference, please send us an email.
`}</pre>

      <h2>24. Changes to this privacy policy</h2>
      <pre>{`We reserve the right to update, revise, amend and/or modify this privacy policy at any time in order to reflect any changes to the way in which we process your personal data or changing legal requirements.

Although we will notify you when major changes are made to this privacy policy, you are expected to periodically review the most up-to-date version so you are aware of any changes. The date of the latest version of the privacy policy will appear at the top of the webpage hosting the policy.
`}</pre>

      <h2>25. Cookies</h2>
      <pre>{`As we have briefly mentioned in this privacy policy, our website uses small files known as cookies to make it work better in order to improve your experience.

To find out more about how we use cookies, please see our Cookies Policy.
`}</pre>

      <h2>26. No Error Free Performance</h2>
      <pre>
        We do not guarantee error-free performance under this privacy policy. We will use reasonable
        efforts to comply with this privacy policy and will take prompt corrective action when we
        learn of any failure to comply with our privacy policy. We shall not be liable for any
        incidental, consequential or punitive damages relating to this privacy policy.
      </pre>
    </>
  );
}
