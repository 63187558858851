import React from "react";
import TextPage from "../text_page/TextPage";
import Email from "../links/Email";
import Telegram from "../links/Telegram";

export default function Terms() {
  return (
    <TextPage>
      <pre>
        {`Affiliate Agreement (Terms of service)
This agreement (hereinafter referred to as the “Agreement”) governs the relationship between:

The Partners, Webmasters (hereinafter referred to as the “Affiliate”) AND KronaSoft OÜ Harju maakond, Tallinn, Pirita linnaosa, Padriku tee T6, 11912 (hereinafter referred to as the “Company”).

By filling in and submitting the sign-up form, by checking boxes “I am over 18 years old or the Age of Majority in my region”, “I have read, understood, and accept the binding Agreement” and “I expressly declare the acceptance of`}{" "}
        <button className="btn-as-link">Privacy Policy</button>{" "}
        {`, the Affiliate gives their consent to and accepts the following Agreement. `}
      </pre>{" "}
      <p>
        <b>Company’s Websites</b>: Website(s) owned, operated, or controlled by the Company or its
        subsidiaries, including the White Label Websites developed, maintained, or hosted by the
        Company or its subsidiaries
      </p>
      <p>
        <b>White Label Website</b> is a website developed, maintained and/or hosted by the Company
        being its promotional tool used by the Affiliate for further advertising, marketing and/or
        promotion of the live webcam site(s) operated by the Company. White Label Website is offered
        as is and the Affiliate is obliged to accept it on an “as is” basis.
      </p>
      <p>
        <b>Offer:</b> All marketing and promotional goals established by the Company as well as its
        subsidiaries and partners, and to their respective set of rules, benefits, revenues, and
        corresponding obligations arising from the execution of the present Agreement, namely
        pertaining to the use of marketing tools offered on the Company’s Websites, including the
        White Label Websites, and in compliance with rules and regulations inherent to their use, as
        well as any means used to promote the Company’s Websites, the Affiliate being bound by the
        principles and provisions set forth in the present Agreement.{" "}
      </p>
      <h2>1. PURPOSE OF THE AGREEMENT</h2>
      <pre>
        {`1.1. The Affiliate has a non-exclusive right to redirect, refer or send visitors or users of their website(s) or the White Label Websites to the Company’s Websites. The Affiliate has no obligation of any kind to exercise such non-exclusive right under the present Agreement.

1.2. The Affiliate has a limited, non-exclusive, non transferable, and revocable right to access, download, and use promotional banners, hypertext links, and other promotional materials created by the Company to be used on the Affiliate’s website(s) or the White Label Websites for the exclusive purpose of advertising, marketing or promoting the Company’s Websites or services provided through the Company’s Websites; however, the license herein granted will automatically and immediately cease upon termination or breach of any term of this Agreement.

1.3. It is expressly agreed between the Parties that the use of promotional materials by the Affiliate on the White Label Websites under this Agreement is not subject to any kind of counterparty from the Company to the Affiliate, other than as described in Clause 6 below. Remuneration as provided in Clause 6 takes into account the use of the Affiliate’s name and/or brand on a White Label Website, as the case may be, by the Company.

1.4. The Affiliate will remain a Program Participant until (1) the Affiliate terminates participation in the Program by notifying the Company at the following e-mail address: `}
        <Email />
        {`; or (2) the Company terminates, with immediate effect, the Affiliate’s participation in the program, with or without justification, by means of notice to the Affiliate’s e-mail address used for contact information. Neither party may be held liable if it cannot have access to the other party’s e-mail address due to any cause, which could not be acknowledged by the notifying party at the time of the notification.

1.5. Nothing herein will be construed as a grant or assignment of any rights to any intellectual property owned by the Company, including, without limitation, any and all of its trademarks or service marks, except as provided in Clause 1.2 above.

1.6. All Program materials, including, but not limited to, all advertising banners, hypertext links, photographic materials, recordings, video, sound, and any form of intellectual property rights on such materials provided to the Affiliate by the Company as part of this Program will remain the property of the Company and may not be copied or reproduced, altered, modified or changed, broadcast, distributed, transmitted or disseminated, sold or offered for sale, in any manner, at anytime, anywhere in the world, except as expressly authorized in by this Agreement in writing by the Company.

1.7. The use of any promotional or other kind of Program materials and/or Program provided by the Company to the Affiliate under this Agreement is at the sole responsibility of the Affiliate and has to be used on an “as is” basis. The Company disclaims all warranties, either expressed or implied including, but not limited to, warranties of merchantability and fitness for a particular purpose, with regard to the Program as well as any and all materials of every kind supplied to the Affiliate as part of this Program. The Affiliate hereby expressly undertakes to hold the Company harmless from any related liabilities concerning the use of mentioned materials by the Affiliate, including holding the Company harmless from any third party’s claims related to the infringement of intellectual property rights, or if the use of such materials is forbidden under the local laws of the Affiliate.


1.8. The Company reserves the right to warn the Affiliate and/or suspend/terminate the Agreement and withhold any pending payments/referrals of whatsoever nature to the Affiliate and/or to any other person/webmaster referred by the Affiliate, whenever the Affiliate uses questionable marketing techniques as set out in Clause 5 below, including, but not limited to chat marketing and any type of unsolicited messages.

1.9. This Agreement and the rights herein are not transferable and may only be used by the Affiliate in the framework of their participation in the Program.
`}
      </pre>
      <h2>2. RELATIONSHIP OF THE PARTIES</h2>
      <pre>
        {`2.1. The relationship between the Affiliate and the Company will be restricted to matters exclusively pertaining to the Program and will be governed entirely by the Agreement.

2.2. The Company will have no control or ownership interests of any kind in the Affiliate’s business or website(s). Notwithstanding, this provision will not be applicable in case the Company provides a White Label Website to the Affiliate under a White Label Broker Service Agreement, which rules will supersede this Agreement to the necessary extent and as expressly agreed by the parties.

2.3. The Affiliate will neither have financial or other interest(s) in the Company nor in any property owned by the Company, its affiliates, agents, successors, or assignees.

2.4. Notwithstanding anything to the contrary in this Agreement, the Company has no direct or indirect control nor ownership interests over the content of performances or services, the manner of performances or services, the time or duration of provision of performances, or the services provided by the Affiliate, in or in association with the Affiliate’s website(s), except as specifically set forth in this Agreement. Notwithstanding, this provision will not be applicable in case the Company provides a White Label Website to the Affiliate under a White Label Broker Service Agreement, which rules will supersede this Agreement to the necessary extent and as expressly agreed by the parties.

2.5. The Affiliate hereby expressly declares not to provide, use, or promote any content that is illegal; stolen; fraudulent or related to fraud; involving crime or criminal activities; related to the sale or distribution of illegal substances, drugs, or weapons; related to the promotion of terrorism; engaging in unauthorized financial activities or scams; related to gambling without proper authorization; nonconsensual; related to child exploitation; abusive or harassing; defamatory; hateful or inciting violence; discriminatory based on race, gender, religion, nationality, disability, sexual orientation, or age; related to revenge porn; involving violence or threats of violence; pretending to be a child or involving impersonation; related to incest; involving deepfakes of a person without their consent or permission; violating any privacy laws or regulations; involving unauthorized use of personal data or identity theft; involving phishing or malware; promoting or facilitating hacking or cyberattacks; infringing intellectual property rights; misleading or deceptive; violating any local, national, or international laws or regulations; engaging in any activities that could harm the reputation or operation of the Company; engaging in any activities that could expose the Company to legal liability or regulatory action; or in violation with any part of the Agreement, law, regulation, or card brand rules on the website(s) of the Company used to participate in the Program.

2.6. The Affiliate will be solely responsible for ensuring that all content distributed through their website(s) complies with the stipulations in clause 2.5. Neither the Company nor any employee, associate, agent, assignee, or successor of the Company will exert or provide any direct or indirect control over monitoring, supervision, prior approval, or review of the content appearing or otherwise distributed on, in, or in association with the Affiliate’s website(s), except in the case of a White Label Website. The Affiliate acknowledges and agrees that any violation of clause 2.5, or the presence of prohibited content as defined therein, may implicate immediate termination of their account and related services. In such cases, the Company reserves the right to withhold any pending or future payments, revenues, or benefits.

2.7. Furthermore, the Affiliate agrees to indemnify and hold harmless the Company from any claims, damages, losses, liabilities, and expenses (including reasonable attorney’s fees) arising out of or related to any breach of these clauses or any content distributed through the Affiliate’s website(s). The Company reserves the right to take any legal action it deems necessary to protect its interests, including but not limited to seeking injunctive relief, pursuing damages, and cooperating with law enforcement authorities.

2.8. In case of any queries, the Affiliate may contact the Company directly at the following e-mail address: `}{" "}
        <Email />{" "}
        {`

2.9. Nothing in this Agreement is construed as constituting or creating any agency, employment relationship, joint or collaborative venture, or partnership between the Affiliate and the Company, and/or its employees, agents, or assignees.
`}
      </pre>
      <h2>3. AFFILIATE DECLARATIONS</h2>
      <pre>{`3.1. The Affiliate expressly declares to acknowledge that the Company’s Websites are used for adult entertainment, being exclusively intended for the entertainment of adults.

3.2. The Affiliate expressly declares to be at least the Age of Majority in their jurisdiction.

3.3. The Affiliate also declares that the persons executing the Agreement have the authority and are entitled to contract on behalf of the Affiliate and that the Affiliate has the right to decide which content may appear on the Affiliate’s websites or the White Label Websites available at the domain names that are the subject matter of this Agreement.

3.4. The Affiliate hereby confirms and acknowledges to have unilaterally decided to enter into an Internet service business, which is of high risk.

3.5. The Affiliate further confirms, acknowledges, and expressly agrees that neither the Company, any agent, or representative of the Company, nor any other person has at any time in the past or present, represented to the Affiliate or has otherwise directly or indirectly communicated by any means to the Affiliate any guarantee, reassurance, or any other communication of any kind regarding:

3.5.1. the potential profitability or likelihood of success of the Affiliate’s participation in the Program as set forth in this Agreement or otherwise;

3.5.2. the possibility or likelihood that the use of any products and/or services provided by the Company pursuant to this Agreement can or will result in the recoupment of any funds expended by the Affiliate for the promotion of the Company’s Website(s) or any other purpose; or

3.5.3. the existence, non-existence, size or any other characteristics of any market for any products or services, which relates to the Affiliate’s participation in the Program pursuant to this Agreement.

3.6. The Company will, at all times, have the right, at its sole and exclusive discretion, to move, including with retroactive nature or effect, the Affiliate from a promotional or standard Program to another promotional or standard Program, adjusting the Affiliate’s associated Program benefits in case the Company deems such measure appropriate due to evidence of the Affiliate’s below standard or high risk performance, namely, in the event the Company suspects foul play, incentive based purchases and/or registrations, and/or generation of traffic through unauthorized sources such as, but not limited to, social networking sites, escort sites, gambling sites, illegal torrent sites, and online auction sites.

3.6.1. The Company will notify the Affiliate of the changes referred in Clause 3.6 above by using the Affiliate’s e-mail address used for contact information.

3.6.2. The Affiliate hereby expressly acknowledges and accepts the measures referred in Clauses 3.6 and 3.6.1 above.

3.7. The Affiliate declares that it will refrain from marketing the content of its website or allowing content search terms that suggest the content contains child exploitation materials, depicts nonconsensual activities, or attracts users using illegal adult content or content that otherwise violates laws, regulations, or card brand rules. Additionally, the Affiliate agrees not to use misleading or deceptive marketing practices, including but not limited to clickbait, false advertising, or any other methods that may deceive users about the nature of the content. The Affiliate further commits to implementing appropriate measures to monitor and prevent the use of such search terms and marketing practices, ensuring full compliance with all applicable legal and regulatory requirements.

3.8. The Affiliate additionally acknowledges that the Company has the right to terminate the Affiliate’s and/or any other person’s participation in the Program within the terms set forth in Clause 1.4 above.

3.9. If the Affiliate’s participation in the Program is terminated for any reason or is not in good standing (for instance, changes in the Affiliate website’s URL or where the Affiliate ceases to offer services on the Internet), the Affiliate will immediately and permanently cease all use of any and all materials provided by the Company and ensure that any and all files containing materials provided by the Company will be removed from the correspondent website(s).

3.10. The Affiliate agrees that the Program is not a franchise or other business opportunity and agrees that such Program does not fall within the scope of any such act or law. Specifically, this Agreement is not by their definition an offer or business opportunity.

3.11. The Affiliate further expressly agrees not to raise any claim of any kind against the Company and agrees to hold the Company harmless from any claim of loss by the Affiliate directly or indirectly resulting from the decision to participate in the Program pursuant to this Agreement.

3.12. The Affiliate further declares and confirms that they will not hereafter claim or purport to claim, either directly or indirectly, in any manner whatsoever, any right, title, interest into, over or upon any intellectual property right owned by the Company or its subsidiaries, unless otherwise agreed in writing by the parties.

3.13. The Affiliate declares, that they are not, nor has the Affiliate been, nor will be in the future, a person, a member of a group or organization who has been or is involved in any criminal conduct or any activity against the law.

3.14. The Affiliate confirms that she/he is aware about the strict prohibition on the registration by the same person (private person and/or legal entity) of the additional accounts under the CamsOffer Program. Any person shall have only one account under the CamsOffer Program. Failure to comply with this rule the Agreement with the Affiliate could be fully and unilaterally terminated by the Company without the fee payment to the Affiliate or the Affiliate could be banned by the Company till the Affiliate provides to the Company full and exact data in relation to her/his Subscription for a valid account registration.

3.15. The Affiliate confirms, that she/he is aware about the strict prohibition on the registration by the Affiliate of the domain name which constitutes as trademark dilution or unauthorized use of a trademark that is identical or confusingly similar to another brand’s trademark including but not limited to the brand names CamsOffer, RichCams. Trademark dilution can occur even if the second trademark is not identical to the original (e.g., the use of a well-known brand name in the domain name). Registration and /or sending traffic from such domains (e.g., IFrame, Redirect, etc.) that are identical or confusingly similar to famous brand trademarks, including but not limited to the brand names CamsOffer, RichCams is strictly prohibited. The Affiliate confirms to compensate for the Company all damages arising out any claims, lawsuits, complaints filed against the Company by affected parties in this regard.

Failure to comply with this rule the registration of the Affiliate account will be canceled by the Company, the Agreement with the Affiliate could be fully and unilaterally terminated by the Company without the fee payment to the Affiliate or the Affiliate could be banned by the Company till the Affiliate provides to the Company full and exact data in relation to a new domain name registered by the Affiliate in accordance with the requirements of the Company for a valid account registration.
`}</pre>
      <h2>4. AFFILIATE DUTIES</h2>
      <pre>
        {`4.1. The Affiliate will provide the Company immediately when requested with a Tax ID Number, Social Security Number, or any other Identification Document that the Company finds necessary. Failure to provide such information will constitute a basis for terminating this Agreement and for forfeiting, as liquidated damages, any and all commissions or fees to which the Affiliate would otherwise be entitled under this Agreement.

4.2. The Affiliate undertakes the duty to inform the Company immediately of any changes including without limitation change of legal status, change of location, change of VAT status (issuance or revocation of VAT number), change of the name and similar changes to personal and/or business data. The Affiliate must provide true, accurate, and updated information about their identity, legal status, and business activities. The Affiliate must conduct thorough due diligence on their business practices, partners, and associates to ensure compliance with all applicable laws, regulations, and industry standards.

4.3. The Affiliate acknowledges and agrees that any advertising, advertising links, or promotional materials of any kind, which promote the Company’s Websites or services provided through the Company’s Websites (except for the promotional tools provided by the Company in the Promo section of the website `}
        <a href="https://camsoffer.com/" target="_blank" rel="noreferrer">
          https://camsoffer.com/
        </a>
        {`) must be previously approved, in writing, by the Company before being displayed or featured on the Affiliate’s website(s).

4.4. The Affiliate will at no time, by using any materials provided in the framework of this Agreement by the Company or by using its promotional materials on the White Label Websites, directly or indirectly display, through any means, negative, misleading, deceptive, harmful, or offensive advertising. This includes making false claims, using misleading advertisements, encouraging or calling for any extreme, illegal, or harmful activities through their marketing efforts, or employing tactics that could harm the reputation of the Company. The Affiliate will be held liable for any and all damages incurred to the Company resulting from or in connection with such conduct, regardless of the motivation.

4.5. The Affiliate is strictly prohibited from working with, partnering with, or engaging in any business activities with any individuals or entities that are listed on any sanctioned or prohibited lists by any government or international organization, known to engage in fraudulent, scamming, or other illegal activities, associated with organized crime or any form of terrorism.

4.6. The Affiliate must conduct thorough due diligence to ensure that their partners, associates, and business activities comply with the prohibitions outlined in Clause 4.4-4.5.

4.7. The Affiliate must regularly monitor and verify that their business partners and associates remain compliant with all applicable laws and regulations. Moreover, the Affiliate must regularly monitor their activities and the activities of their sub-affiliates or partners to ensure compliance with this Agreement.

4.8. The Affiliate must disclose the source of their web traffic and ensure that all traffic directed to the Company’s services is obtained through legitimate and ethical means.

4.9. The Affiliate must maintain control over their traffic sources and must not engage in any practices that generate fraudulent or low-quality traffic, including but not limited to bot traffic, click farms, or any form of artificial inflation of traffic metrics.

4.10. The Affiliate understands and agrees that it is solely his/her/its responsibility to pay all applicable taxes in regard with the payments received from the Company under this Agreement. The Affiliate understands that it is his/her/its mandatory obligation to pay all applicable taxes, submit relevant tax declarations to tax authorities, provide Company with relevant tax/VAT IDs should the Affiliate exceed the local threshold, thereby requiring the registration and payment of any value added or similar tax where applicable.

4.11. The Affiliate understands and agrees to provide without any delay to the Company confirmation of any and all taxes paid upon request.

4.12. The Affiliate must comply with all applicable advertising standards and regulations, including those set by advertising platforms and industry bodies.

4.13. The Affiliate must ensure that all promotional materials and advertisements are clearly identifiable as such and do not violate any third-party intellectual property rights.

4.14. The Affiliate must provide the Company with regular reports detailing their promotional activities, sources of traffic, and compliance measures.

4.15. The Affiliate must comply with all applicable data protection and privacy laws, including but not limited to the EU General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA), where applicable.

4.16. The Affiliate must collect, store, and process data about users only to the extent allowed by applicable laws and as necessary for the legitimate business purposes outlined in this Agreement. Furthermore, the Affiliate must implement appropriate technical and organizational measures to ensure the security and confidentiality of user data.

4.17. The Affiliate acknowledges and agrees that they are responsible for the immediate removal of any content on their website(s) or affiliate website(s) that the Company deems to be infringing, illegal, non-consensual, or otherwise unacceptable upon receiving a request from the Company.

4.17.1. Infringing content includes, but is not limited to, any material that violates the intellectual property rights of the Company (including copyrights, trademarks, patents, and trade secrets of the Company), as well as the intellectual property rights of any third parties.

4.17.2. Illegal content includes, but is not limited to, any material that is unlawful under applicable laws, including content related to criminal activities, fraud, scams, or other forms of illegal conduct.

4.17.3. Non-consensual content includes, but is not limited to, any material that has been published or shared without the consent of the individuals depicted or involved.

4.17.4. Unacceptable content includes any material that the Company, at its sole discretion, considers harmful, offensive, misleading, or damaging to its reputation or business interests.

4.17.5. Content that is deemed to be related to criminal activities, illegal, non-consensual, or involving immediate threats must be removed immediately upon notice from the Company. Other infringing or unacceptable content must be removed as soon as possible, but no later than 24 hours from the time of the notice. In cases where immediate removal is not possible, the Affiliate must remove the content within a maximum of 7 calendar days from the time of the notice.

4.18. Failure to comply with the removal requests within the specified timeframes will constitute a breach of this Agreement and may result in immediate termination of the Agreement by the Company. Upon termination due to non-compliance, the Affiliate will forfeit any pending or future payments, revenues, or benefits, and may be subject to legal action to recover any damages incurred by the Company.

4.19. The Company reserves the right to terminate this Agreement immediately if the Affiliate is found to be in breach of any of the provisions outlined in this clause. In the event of termination for non-compliance, the Affiliate will forfeit any pending or future payments, revenues, or benefits and may be subject to legal action to recover any damages incurred by the Company.

4.20. The Affiliate agrees to indemnify, defend, and hold harmless the Company from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising from or related to the Affiliate’s breach of this Agreement or any applicable laws and regulations.
`}
      </pre>
      <h2>5. SPAM</h2>
      <pre>
        {`5.1. In case the Affiliate uses spam, the present Agreement will be immediately terminated, since the Company has `}
        <b>a zero-tolerance policy for spamming.</b>
        {`

5.2. In case of any type of spamming, the Affiliate will further be barred from future participation in the Program, and all funds due to the Affiliate resulting from the participation of the Affiliate in the Program will be forfeited to the Company.

5.3. The Affiliate agrees and warrants that they will not use any form of spam, including but not limited to, unsolicited electronic mail messages, unsolicited instant messages, online classified ads, auction spam, web search engine spam, blog postings, Wiki spam, social networking site spam, newsgroup postings, Telegram postings, or any other form of unsolicited communication in promoting the Affiliate’s website(s) or in directing users to the Company’s Website(s).

5.3.1. The Affiliate further warrants to refrain from marketing the content of its website or permitting content search terms to give the impression that the content contains child exploitation materials or the depiction of nonconsensual activities or attract users to its website by utilizing adult content that is illegal or otherwise violates the law, regulations, or card brand rules.

5.3.2. The Affiliate acknowledges and agrees that the Company has the right to immediately and without notice terminate the participation of the Affiliate in the Program if the Company, at its sole and absolute discretion, determines that the Affiliate uses any form of mass unsolicited electronic mail messages, newsgroup postings, cookie stuffing marketing techniques, pressured selling or trading, malware, Telegram postings, or any other form of «spamming.»

5.3.3. The Affiliate agrees to indemnify and hold harmless the Company from any claims, damages, losses, liabilities, and expenses (including reasonable attorney’s fees) arising out of or related to any spamming activities conducted by the Affiliate or any breach of this Сlause 5. The Company reserves the right to take any legal action it deems necessary to protect its interests, including but not limited to seeking injunctive relief, pursuing damages, and cooperating with law enforcement authorities.

5.3.4. The Affiliate further agrees to implement and maintain robust anti-spam measures, including but not limited to, double opt-in for email subscriptions, regular monitoring of marketing practices, and compliance with all applicable anti-spam laws and regulations, including the the ePrivacy Directive (EU) and CAN-SPAM Act (USA).

5.3.5. The Affiliate must maintain accurate records of all marketing communications and provide these records to the Company upon request. Failure to provide such records may result in immediate termination of the Agreement.

5.3.6. The Affiliate understands that any violation of anti-spam policies can lead to severe legal and financial penalties for the Company, and therefore, any breach of this Clause 5 will be treated as a material breach of the Agreement.

5.4. The Affiliate will not, directly or indirectly, by any means, trick, link, or forge in any manner any of the following content or material to any of the Company’s Websites through any hyperlinks maintained or created on the Affiliates website(s):

5.4.1. Any material depicting brutality, rape, torture, incest, bestiality, or such;

5.4.2. Any material which is displayed or transmitted in a way as to constitute harmful matter or indecent communications to minors;

5.4.3. Any material which constitutes or may be subject to be considered as child pornography, bestiality, rape, torture and/or which involves depictions of nudity or sexuality by an underage-looking performer whether the performer be real or computer generated (i.e. someone who looks younger than the Age of Majority in relevant regions), or by a performer who is portrayed or made to appear to be a person under the Age of Majority by virtue of script, make-up, demeanor, costuming, setting, etc.;

5.4.4. Any material, which is threatening, abusive, hateful, defamatory, libelous, slanderous, scandalous, or injurious to the reputation of any person or entity;

5.4.5. Any material which constitutes an infringement, misappropriation, or violation of any person’s rights of publicity, privacy rights, or any person’s intellectual property rights, including, but not limited to, copyrights, trademarks, and service rights. This includes photos altered to look like celebrities or other private persons;

5.4.6. Any program, file, data stream, or other material which contains viruses, malware, spyware, worms, Trojan horses, malicious JavaScript, or any other feature, which takes control of a third party computer or CPU without their permission and which may cause damage (intended or unintended) to any computer equipment, loss or corruption of data or programs, or inconvenience to any person;

5.4.7. Any material which contains any form of solicitation for prostitution or personal contacts outside the context of websites, or which promotes and/or facilitates incentive-based websites, warez websites, hacking, cracking, or the downloading and/or trading of unauthorized audio files and/or any other unauthorized content protected by third parties intellectual property rights;

5.4.8. Any material which does not fully comply with the applicable legislation and regulation.

5.4.9. Any domain name which constitutes as trademark dilution or unauthorized use of a trademark that is identical or confusingly similar to another brand’s trademark. Trademark dilution can occur even if the second trademark is not identical to the original (e.g., the use of a well-known brand name in the domain name). Sending traffic from such domains (e.g., IFrame, Redirect, etc.) that are identical or confusingly similar to famous brand trademarks is strictly prohibited.

5.5. The Affiliate understands and accepts that under no circumstances will the Company and its subsidiaries be, to the extent permitted by law, liable for any direct, indirect, incidental, special, consequential, or punitive damages that result from the violation of this Agreement.

5.5.1. The Affiliate recognises the duty to indemnify, pay the costs of defense, and hold harmless the Company and its subsidiaries, its officers, directors, affiliates, attorneys, shareholders, managers, members, agents, and employees from any and all claims, losses, liabilities, or expenses (including reasonable attorneys’ fees or legal fees otherwise) brought by third parties arising out of or related to the Affiliate’s conduct, statements, or actions, as well as breach of any term, condition, or promise contained herein.
`}
      </pre>
      <h2>6. FEES</h2>
      <pre>
        {`6.1. The Company will have the right to deny, withhold payment to the Affiliate, and/or terminate the Affiliate’s participation in the Program and this Agreement if the Company concludes that the Affiliate has directly or indirectly engaged in, or encouraged fraudulent or misleading activity.

6.2. The Company will have the right, in its sole and exclusive discretion, to determine objectively what constitutes fraudulent or misleading activity and whether the conduct of the Affiliate, directly or indirectly constituted and/or encouraged fraudulent or misleading activity. The Company’s determination that fraudulent or misleading activity has or is occurring will be conclusive as to that issue.

6.3. For the effects of Clause 6.1 above fraudulent or misleading activity includes, but is not limited to, suspected foul play, incentive based purchases, generation of traffic through unauthorized sources, any form of spamming and the use and advertisement of illegal or prohibited content, such as child exploitation materials and depiction of nonconsensual activities, by the Affiliate.

6.4. The referral fees will be payable in the U.S. Dollars or USDT. On their account page, Affiliates will find a dashboard with statistics about applicable referral fees, purchases, chargebacks, bonuses, etc.

6.5. The referral fees payable as part of the Program will be listed as follows:

6.5.1. Standard Revenue Share commission options are available as follows:

- 25% from the user's funds spent on the model;
- 5% of funds earned by referral models;
- 10% of funds earned by referral partners/webmasters.

`}
        <b>
          Affiliates are not allowed to register a secondary account under their own referral link
          as it will lead to an artificial and unfounded increase of earnings. Accounts violating
          this Agreement will be terminated;
        </b>
        {` 

6.5.2. The payout periods for affiliate commissions are as follows:

Payments are made every Friday for the previous week (from Monday to Sunday).

6.5.3. Current minimum payouts are as follows:

The minimum payout amount is $100. 

6.5.4. Available payout methods are listed below:
- Tether USDT (TRC20)* ( 1% + 2 USDT fee);
- Wire transfer** ($6 commission for individuals; 1% for legal entities, but not less than $25);


* When choosing Tether USDT as your payment method, please ensure that you have chosen the correct TRC-20 network and that the wallet information is entered correctly. Entering an incorrect wallet address will lead to loss of funds with no refund option.
** Wire transfer (direct bank transfer to account) is available for affiliates from the SEPA zone.

6.6. The Company reserves the right, in its sole and exclusive discretion, at any time, to alter or modify the Program (including, for example, but not limited to, changes in the scope of available referral fees, fee schedules, payment procedures, and Program rules) in order to improve the Program’s effectiveness.

6.7. The Company reserves the right, in its sole and exclusive discretion, at any time, to alter or modify the Program (including, for example, but not limited to, changes in the scope of available referral fees, fee schedules, payment procedures, and Program rules) in order to improve the Program’s effectiveness.

6.8. All changes will be duly posted or published on camsoffer.com and will from then be immediately binding for all participants, unless the Affiliate would terminate this Agreement upon receipt of such changes.

6.9. In case the Affiliate disagrees with a change to the terms of this Agreement made by the Company, the Affiliate may terminate the present Agreement in compliance with the provisions set forth in Clause 9, being immediately excluded from the Program.

6.10. The continued participation of the Affiliate in the Program following the posting of a change notice or new agreement by the Company on the referred link will constitute a binding acceptance of the said change(s).
`}
      </pre>
      <h2>LIMITED LIABILITY OF THE COMPANY</h2>
      <pre>{`7.1. Under no circumstances will the Company, its employees, independent contractors, authors, agents, representatives, assignees, and successors will be, to the extent permitted by law, liable to the Affiliate, or any other person or entity, for any direct or indirect losses, injuries, or incidental or consequential damages of any kind (including, without limitation, damages for loss of business profits, business interruption, loss of business information, other incidental or consequential damages, or any other pecuniary loss) with regard to any link to any of the Company’s Website, or arising from or in connection with the use of the Program materials, or due to any mistakes, omissions, delays, errors, interruptions in the transmission, or receipt of the Company’ services, content or Program materials, including, without limitation, any losses due to server problems or due to incorrect placement of HTML or other programming languages.

7.2. Notwithstanding the foregoing express limitations of liability, should the Company, its officers, employees, successors, or assignees be held liable for damages, injuries, or losses of any kind, directly or indirectly resulting from participation in the Program, the aggregate liability arising with respect to and under this Agreement and the Program for any and all claims, injuries, damages, or losses will not exceed the total amount of referral fees paid or payable to the Affiliate under this Agreement.
`}</pre>
      <h2>8. MONITORING/SUPERVISION</h2>
      <p>
        8.1. The Company will not monitor, supervise, or review, and will not be, to the extent
        permitted by law, responsible for any content appearing or otherwise distributed on, in or
        in association with the Affiliate’s website(s), except for content which is supplied to the
        Affiliate by the Company, provided that the said content supplied to the Affiliate by the
        Company has not been altered or modified by the Affiliate or by any other party, except for
        when otherwise agreed by the parties in writing.
      </p>
      <h2>9. DURATION AND TERMINATION OF THE AGREEMENT</h2>
      <pre>{`9.1. This Agreement will enter into force on the date on which the sign-up form is received by the Company and after the Affiliate accepts the present Agreement by clicking the “I am over 18 years old or the Age of Majority in my region”, “I have read, understood, and accept the binding Agreement” and “I expressly declare the acceptance of Privacy Policy” checkboxes. The Agreement will end when terminated by either party in compliance with the provisions herein.

9.2. The Affiliate will remain a Program participant in good standing at all times, since and as long as the Affiliate is receiving benefits or is otherwise participating in the Program.

9.3. The Affiliate will cease to be a participant in good standing and will be subject to immediate termination of all benefits without prior notice if there is a failure to perform under or breach of any part of this Agreement, including, without limitation, giving out false information or failing to report adequately on its promoting activities upon the Company’s request.

9.4. Either party may terminate this Agreement at any time, with or without cause, by giving the other party in electronic form notice of termination.

9.5. The Affiliate will not be entitled to receive any commissions and/or referral fees for any “referrals” delivered or received by the Company after the date and time of termination.

9.6. Upon termination of this Agreement, the Affiliate will immediately cease using the Company’s materials, which is or not protected by any intellectual property rights, including, without limitation, trademarks, logos, promotional banners, hypertext links, and other promotional materials of the Company, and will remove any materials supplied to the Affiliate by the Company, and/or referring to the Company, including, without limitation, any banner ads, from the Affiliate’s website(s).

9.7. All of the Affiliate’s warranties, indemnities and obligations, which by their nature are designed to survive to the termination of this Agreement, will extend beyond the termination of this Agreement.
`}</pre>
      <h2>10. ASSIGNMENT</h2>
      <pre>{`10.1. All promises, obligations, duties and warranties made by the Affiliate in this Agreement are personal to the Affiliate and neither these promises, obligations, duties and warranties, nor any benefits hereunder may be assigned by the Affiliate to any other person or entity.

10.2. The Company may at any time and without limitation, freely assign all or part of its duties, obligations and benefits hereunder. The Affiliate duly acknowledges and accepts this assignment upon communication by the Company.
`}</pre>
      <h2>11. MISCELLANEOUS</h2>
      <pre>{`11.1. This Agreement constitutes the entire agreement between the Affiliate and the Company with respect to the subject matter hereof, and supersedes and/or cancels all other prior agreements, discussion, or representations, whether written or oral.

11.2. The Parties hereby declare and agree that the provisions of this Agreement may be superseded by specific Promotions launched by the Company and accepted by the Affiliate, if expressly stated, binding the parties until the term of the Promotion.

11.3. Any future representations made by the Company or any of its agents or representatives will not supersede the provisions stated in the present Agreement, unless submitted in writing as an express amendment of the Agreement and if accepted (including any tacit acceptance when continuing the contractual relationships with the Company upon the receipt of such express amendment) by the Affiliate.

11.4. The failure of the Company to enforce any of the specific provisions of this Agreement will neither preclude any other or further enforcement of such provision(s), nor the exercise of any other right hereunder.
`}</pre>
      <h2>12. DATA PROTECTION</h2>
      <pre>{`12.1. The Affiliate authorizes the Company to process technical data related to the redirection of persons to the Company’s Websites through the use of links displayed on the Affiliate’s website(s) and to process the Affiliate or its employees’ personal data provided by the Affiliate or collected during the registration process of the Affiliate, during the period of this Agreement and, after termination of this Agreement, during the period necessary for the Company to comply with legal requirements.

12.2. The Affiliate agrees and allows the Company to collect, process, and transfer to its processors, the following types of data/information related to the Affiliate:

12.2.1. Information that the Affiliate voluntarily provides to the Company, such as names, e-mail address, address, date of birth, and other miscellaneous account information submitted through the Affiliate registration form;

12.2.2. Software and hardware attributes, along with any other data that can be gained from the general Internet environment, such as browser type, IP address, etc.;

12.2.3. Private communications, such as e-mail messages to the Company’s staff. 

12.3. The Company will use personal data of the Affiliate for mainly general purposes, such as accounting and management of suppliers, contacting the Affiliate, customizing the Program materials, and for promotional marketing purposes, to the extent allowed by law.

12.4. The Company shall not be liable for damages caused to the temporary unavailability of services related to technical problems (such as: work on improvement of server and software, hacker attacks, a problem of the data center, routing, etc.).

12.5. The Company is not responsible for damages and/or loss of the content resulting from the hacker attacks any other attacks and unlawful acts directed against websites of the Company.

12.6. The Affiliate undertakes to receive all necessary consents and agreements from the data subjects that are redirected from Affiliate’s websites to the Company’s Websites.

12.7. The Affiliate acknowledges and agrees that a) the European Data Protection Legislation will apply to the processing of Affiliate Personal Data if the Affiliate’s residence is within the territory of the EEA; b) the Non-European Data Protection Legislation will apply to the processing of Affiliate Personal Data if the Affiliate’s residence is in Non-European territory. More information regarding the processing and protection of personal data you can find in our Privacy Policy.

12.8 For California residents: according to California Consumer Privacy Act (CCPA) Affiliates from California can exercise their rights regarding their Personal Information according to the abovementioned Act. All of the rights that are available to Affiliates from California also include other rights described in Terms of Service and Privacy Policy.
`}</pre>
      <h2>13. APPLICABLE LAW AND JURISDICTION</h2>
      <pre>{`13.1. The parties will irrevocably submit to the exclusive jurisdiction of the courts of Estonia for the purposes of hearing and determining any dispute arising out of this Agreement.

13.2. This Agreement, all matters arising from it, and any dispute resolutions referred to the above will be governed by and construed in accordance with the laws of Estonia, notwithstanding conflict of law provisions and other mandatory legal provisions.
`}</pre>
      <h2>14. NOTICES</h2>
      <p>
        Any notice, recommendation or advice by each party to the other hereunder will be provided
        to the following contacts:
      </p>
      <b>Affiliate</b>
      <p>Email or Telegram mentioned in the sign-up (profile) form.</p>
      <b>Company</b>
      <p>
        Email <Email /> or Telegram <Telegram />
      </p>
    </TextPage>
  );
}
