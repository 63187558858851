import React, { useState } from "react";
import iconLogin from "../../images/icon/solar_user-bold.svg";
import CusButton from "../mui/CusButton";
import ChoseLang from "../mui/ChoseLang";
import "../../style/home/header.scss";
import SignUpModal from "../common/SignUpModal";
import { LOGIN, SIGN_UP } from "../common/consts";
import { useTranslation } from "react-i18next";
import { API_LOGIN } from "../../api/login";

export default function HomeHeader({ isConfirm, setOpenTypeApprove }) {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const { t } = useTranslation();

  const logout = () => {
    API_LOGIN.logout()
      .then((e) => window.location.reload())
      .catch((e) => console.log(e));
  };

  return (
    <>
      <header className="header">
        <div className="header__content">
          <div className="header__text">
            <p className="header__text__name">{t("CamsOffer.com")}</p>
            <span className="header__text__info">
              {t("Webcam affiliates offers by")}{" "}
              <a
                className="header__text__info__name"
                href={"http://richCams.com"}
                target="_blank"
                rel="noreferrer"
              >
                {t("RichCams")}
              </a>
            </span>
          </div>

          {isConfirm ? (
            <div className="header__controls">
              <CusButton
                img={iconLogin}
                text={"Dashboard"}
                onClick={() => (window.location.href = "/webmaster")}
              />
              <CusButton className="sing-up__button" text={"Logout"} onClick={logout} />
              <ChoseLang />
            </div>
          ) : (
            <div className="header__controls">
              <CusButton img={iconLogin} text={"Login"} onClick={() => setOpenLoginModal(LOGIN)} />
              <CusButton
                className="sing-up__button"
                img={iconLogin}
                text={"Sign up"}
                onClick={() => setOpenLoginModal(SIGN_UP)}
              />
              <ChoseLang />
            </div>
          )}
        </div>
      </header>
      <SignUpModal
        isOpen={!!openLoginModal}
        type={openLoginModal}
        setOpen={setOpenLoginModal}
        setOpenTypeApprove={setOpenTypeApprove}
      />
    </>
  );
}
