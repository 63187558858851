import React from "react";
import { useTranslation } from "react-i18next";

export default function AffiliatesByCard({ statistic, className }) {
  const { t } = useTranslation();

  return (
    <div className={` affiliates__card ${className}`}>
      <div className="affiliates__card__header">
        <h3>{t(statistic.title)}</h3>
        <span>{statistic.date}</span>
      </div>
      <ul className="affiliates__card__items">
        {statistic.content.map((item, indx) => (
          <li className="affiliates__card__item" key={indx}>
            <span className="affiliates__card__item__name">{item.name}</span>
            <span className="affiliates__card__item__value">{item.sum}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
