import React from "react";
import imgTg from "../../images/advantages/logos_telegram.svg";
import imgMail from "../../images/icon/mail.svg";
import ApproveModal from "./ApproveModal";
import { useTranslation } from "react-i18next";
import Policy from "./policy/Policy";
import Cookie from "./cookie/Cookie";
import P18usc2257 from "./P18usc2257";
import DMCA from "./DMCA";
import Terms from "./terms/Terms";
import Telegram from "./links/Telegram";
import Email from "./links/Email";

const approveTexts = {
  service: { title: "Terms of service", text: <Terms /> },
  privacy: { title: "Privacy polices", text: <Policy /> },
  cookies: { title: "Cookies policy", text: <Cookie /> },
  P18usc2257: { title: "18 U.S.C 2257", text: <P18usc2257 /> },
  dmca: { title: "DMCA", text: <DMCA /> },
};

export default function HomeFooter({ openTypeApprove, setOpenTypeApprove }) {
  const { t } = useTranslation();

  const titleModal = approveTexts[openTypeApprove]?.title;
  const textModal = approveTexts[openTypeApprove]?.text;

  return (
    <>
      <div className="home__footer">
        <div className="home__footer__content">
          {/* <div className="home__footer__left">
            <span className="home__footer__traffic"> {t("Adult WebCam Traffic LTD")} </span>
            <span className="home__footer__address">
              <img src={imgLocation} alt="location" />
              <address>44, avenue J.F. Kennedy L-1855 Luxembourg</address>
            </span>
          </div> */}
          <div className="home__footer__center">
            <div className="home__footer__actions">
              <button onClick={() => setOpenTypeApprove("service")}>{t("Terms of service")}</button>
              <button onClick={() => setOpenTypeApprove("privacy")}>{t("Privacy polices")}</button>
              <button onClick={() => setOpenTypeApprove("cookies")}>{t("Cookies policy")}</button>
            </div>
            <div className="home__footer__actions__two">
              <span className="home__footer__copyright">
                {" "}
                {t(`©${new Date().getFullYear()} CamsOffer`)}
              </span>
            </div>
          </div>
          <div className="home__footer__right">
            <span>{t("For any questions write to:")}</span>
            <div className="home__footer__connection">
              <a target="_blank" rel="noreferrer" href={"https://t.me/richCams"} className="action">
                <img alt="tg" src={imgTg} /> <Telegram />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto: affiliates@richcams.com"
                className="action"
              >
                <img alt="mail" src={imgMail} /> <Email />
              </a>
            </div>
          </div>
        </div>
      </div>

      <ApproveModal
        setOpen={setOpenTypeApprove}
        isOpen={!!openTypeApprove}
        title={titleModal}
        text={textModal}
        type={openTypeApprove}
      />
    </>
  );
}
