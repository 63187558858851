import React from "react";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import { useTranslation } from "react-i18next";

export default function ApproveModal({ isOpen, setOpen, title, text, type }) {
  const { t } = useTranslation();
  return (
    <Modal
      ariaHideApp={false}
      className={`approve-modal ${type} ${t("currentLang")}`}
      isOpen={isOpen}
      onRequestClose={(e) => setOpen(false)}
    >
      <CloseModal close={(e) => setOpen(false)} />
      <h1 className={`approve-modal__title ${title}`}> {t(title)}</h1>
      {typeof text === "string" ? (
        <div className="approve-modal__text" dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <div className="approve-modal__text">{text}</div>
      )}
    </Modal>
  );
}
