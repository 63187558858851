import React from "react";

export default function P18usc2257() {
  return (
    <section className="cookie">
      <h1>18 U.S.C. § 2257 Statement</h1>
      <p>
        Any actual human beings depicted in images appearing on this website were over the age of 18
        at the time those images were recorded.
      </p>
      <h3>Exemption: Content Produced by Third Parties</h3>
      <p>
        The operators of this website are not the "producers" of any depictions of actual or
        simulated sexually explicit conduct which may appear on this website. More specifically, the
        operators of this website limit their handling of such content, and only perform the
        activities of transmission, storage, retrieval, hosting, and/or formatting of material that
        may depict sexually explicit conduct, all of which material appears on the website as the
        result of actions taken by third-party users of the website. All portions of the website
        that contain such user-generated material are under the control of the relevant user, for
        whom this website is provided as an online service by its operators. Pursuant to 18 U.S.C. §
        2257(h)(2)(B)(v) and 47 U.S.C. § 230(c), the operators of this website reserve the right to
        delete materials appearing on the site as the result of actions taken by the website’s
        users, which materials are deemed, in the operator’s sole discretion, to be indecent,
        obscene, defamatory, or inconsistent with the policies and terms of service for this
        website.
      </p>
      <h3>Exemption: Content Produced by Website Operators</h3>
      <p>
        To the extent that any images appear on the website, for which the operators of this website
        may be considered the “producer,” those images are exempt from the requirements of 18 U.S.C.
        § 2257 and 28 C.F.R. § 75 for one or more of the following reasons: (i) the produced images
        do not portray any sexually explicit conduct defined in 18 U.S.C. §§ 2256(2)(A); (ii) the
        produced images do not portray depictions of the genitals or pubic area created after July
        27, 2006; (iii) the produced images do not portray simulated sexually explicit activity
        occurring after the effective date of 18 U.S.C. § 2257A; and/or (iv) the produced images
        were created prior to July 3, 1995.
      </p>
      <h3>Designated Records Custodian</h3>
      <p>
        Without limiting in any way the applicability of the above-stated exemptions, the operators
        of this website have designated the custodian, whose address appears below, to be the keeper
        of original records described in 18 U.S.C. § 2257 and 28 C.F.R. § 75 for all materials
        appearing on this website that fall into one of the following two categories: (i) marketing
        and advertising materials that contain visual depictions of actual or simulated sexually
        explicit conduct, which materials have been acquired or created by the website’s operators
        for the purpose of promoting the website; and (ii) all visual depictions of models, actors,
        actresses and other persons (each a “Performer”) who have elected to enable tipping, private
        shows, group shows, or any other service that permits collection, by the Performer, of
        tokens or any other form of virtual funds. Age verification records are collected and
        reviewed before permitting a Performer to collect virtual funds.
      </p>
      {/* <p>
          The aforementioned records and their custodian can be found at the following location:
        </p> */}
      <p>
        Records required to be retained in accordance with the above laws are maintained by the
        following records custodian: <a href="mailto:admin@richcams.com">admin@richcams.com</a>
      </p>
    </section>
  );
}
