import axios from "axios";

export const API_URL = "/api";

axios.interceptors.response.use((response) => {
  if (response.data.message === "Unauthenticated.") {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.href = "/";
  }
  return response;
});
