import React from "react";

export default function Spinner({ style }) {
  return (
    <div className="spinner lds-roller" style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
