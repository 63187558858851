import React, { useEffect, useState } from "react";
import HomeHeader from "../components/home/HomeHeader";
import Welcome from "../components/home/Welcome";
import Advantages from "../components/home/Advantages";
import Affiliates from "../components/home/Affiliates";

import HomeFooter from "../components/home/HomeFooter";
import Actions from "../components/home/Actions";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import { IS_CONFIRM } from "../components/common/consts";

export default function Home() {
  const { t } = useTranslation();

  const [query] = useSearchParams();
  const successToast = query.get("confirmEmail");
  const errorToast = query.get("errorEmail");
  const [isConfirm, setIsConfirm] = useState(IS_CONFIRM);

  useEffect(() => {
    if (successToast) {
      console.log("ff");
      toast(t("your email has been successfully confirmed"), {
        style: { background: "#008000", color: "#fff" },
      });
      setIsConfirm(true);
      window.history.pushState("", "", "/");
      return;
    }

    if (errorToast) {
      toast("An error has occurred", { style: { background: "#f00", color: "#fff" } });
      return;
    }
  }, []);

  const [openTypeApprove, setOpenTypeApprove] = useState(false);

  return (
    <>
      <main className={`home ${t("currentLang")}`}>
        <HomeHeader isConfirm={isConfirm} setOpenTypeApprove={setOpenTypeApprove} />
        <Welcome />
        <Advantages />
        <Affiliates />
        <Actions />
        <HomeFooter openTypeApprove={openTypeApprove} setOpenTypeApprove={setOpenTypeApprove} />
      </main>
      <ToastContainer />
    </>
  );
}
