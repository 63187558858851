import axios from "axios";
import { API_URL } from "./main";

export const login = async (data) => {
  let response = await axios({
    method: "POST",
    url: `${API_URL}/user/signin`,
    data: data,
  });
  return response;
};

export const confirm = async (data) => {
  let response = await axios({
    method: "POST",
    url: `${API_URL}/user/confirm`,
    data: data,
  });
  return response;
};

export const logout = async (data) => {
  let response = await axios({
    method: "GET",
    url: `${API_URL}/user/logout`,
    data: data,
  });
  return response;
};

export const register = async (data) => {
  let response = await axios({
    method: "POST",
    url: `${API_URL}/user/signup`,
    data: data,
  });
  return response;
};

export const resetPas = async (data, type) => {
  const URL = `${API_URL}/user/password/reset`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const API_LOGIN = {
  login: login,
  register: register,
  confirm: confirm,
  logout: logout,
  resetPas: resetPas,
};
