import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../components/mui/Spinner";
import { API_LOGIN } from "../api/login";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ConfirmEmail() {
  const { t } = useTranslation();

  const [query] = useSearchParams();
  const key = query.get("key");
  const navigate = useNavigate();

  useEffect(() => {
    const data = { key: key };

    API_LOGIN.confirm(data)
      .then((e) => {
        console.log(e);
        navigate({
          pathname: "/",
          search: "confirmEmail=true",
        });
      })
      .catch((e) => {
        console.log(e);
        navigate({
          pathname: "/",
          search: "errorEmail=true",
        });
      });
  }, []);

  return (
    <main
      className={`home ${t("currentLang")}`}
      style={{
        display: "flex",
        minHeight: "100vh",
        background: "black",
      }}
    >
      <Spinner style={{ margin: "auto" }} />
    </main>
  );
}
