import React from "react";
import imgWebcam from "../../images/icon/subway-webcam.svg";
import imgWomen from "../../images/icon/solid-women.svg";
import imgArrowRight from "../../images/icon/arrow-up-right.svg";
import { useTranslation } from "react-i18next";
export default function Actions() {
  const { t } = useTranslation();

  return (
    <div className="home__actions">
      <div className="home__actions__content">
        <a
          href={"https://richCams.com"}
          target="_blank"
          rel="noreferrer"
          className="home__actions__cams"
        >
          <img alt="cams" src={imgWebcam} />
          <span className="home__actions__cams__text">{t("Adult Live Cams by RichCams")}</span>
          <img alt="arrow right" src={imgArrowRight} />
        </a>
        <a
          href={"https://start.richcams.com/"}
          target="_blank"
          rel="noreferrer"
          className="home__actions__model"
        >
          <img alt="model" src={imgWomen} />
          <span className="home__actions__model__text">{t("Become a model on RichCams")}</span>
          <img alt="arrow right" src={imgArrowRight} />
        </a>
      </div>
    </div>
  );
}
