import React, { useRef } from "react";
import i18n from "../../i18n";

import imgFlagRu from "../../images/icon/flag/ru.svg";
import imgFlagEs from "../../images/icon/flag/es.svg";
import imgFlagGer from "../../images/icon/flag/ger.svg";
import imgFlagIt from "../../images/icon/flag/it.svg";
import imgFlagUsa from "../../images/icon/flag/usa.svg";
import imgArrowUp from "../../images/icon/iconamoon_arrow-up-2-light.svg";
import { useTranslation } from "react-i18next";
import { getLang } from "../../utils/utils";
import { useLocalStorage } from "usehooks-ts";

export default function ChoseLang() {
  const chooseLangRef = useRef(null);
  // const langs = ["English", "Russian", "Italiano", "Deutch", "Espanol"];
  const langs = ["en", "ru"];
  // const langs = i18n.languages;
  // console.log(Object.keys(i18n.store.data));

  const chosenLang = getLang();
  const [selectLang, setSelectLang] = useLocalStorage("lang", chosenLang);

  const getSelect = (lang) => (lang === chosenLang ? "select" : "");
  const { t } = useTranslation();

  const flagToLang = {
    ru: imgFlagRu,
    en: imgFlagUsa,
    es: imgFlagEs,
    de: imgFlagGer,
    it: imgFlagIt,
  };

  const catLangByLang = {
    ru: "Ru",
    en: "En",
    es: "Es",
    de: "De",
    it: "It",
  };

  const lcsLangByLongLang = {
    ru: "ru",
    en: "en",
  };

  const changeLang = (lang) => {
    i18n.changeLanguage(lcsLangByLongLang[lang]);
    setSelectLang(lcsLangByLongLang[lang]);
  };

  const currentImg = flagToLang[selectLang];

  return (
    <div className="chose-lang" onMouseOver={() => chooseLangRef.current.classList.remove("close")}>
      <span className="lang-item main select">
        <img src={currentImg} alt="flag" />
        <span>
          {catLangByLang[selectLang]}
          <img src={imgArrowUp} alt="" className="arrow" />
        </span>
      </span>
      <ul ref={chooseLangRef}>
        {langs.map((el, indx) => (
          <li
            key={indx}
            onClick={() => {
              changeLang(el);
              chooseLangRef.current.classList.add("close");
            }}
            className={`lang-item ${getSelect(el)}`}
          >
            <img src={flagToLang[el]} alt="flag" />
            <span>{t(catLangByLang[el])}</span>
          </li>
        ))}
      </ul>
      <div className="chose-lang__plug" />
    </div>
  );
}
