import React from "react";
import imgLock from "../../images/icon/lock-fill.svg";
import imgPlayVideo from "../../images/icon/video-play.svg";
import imCursorCLick from "../../images/icon/cursor-click.svg";
import imgTrafficUp from "../../images/icon/traffic-up.svg";
import imgUser from "../../images/icon/user.svg";
import imgCheck from "../../images/advantages/check-one.svg";

import imgBitcoin from "../../images/advantages/bitcoin.svg";
import imgDollar from "../../images/advantages/dollar.svg";

import imgTg from "../../images/advantages/logos_telegram.svg";
import imgMail from "../../images/icon/mail.svg";
import { useTranslation } from "react-i18next";

export default function Advantages() {
  const { t } = useTranslation();

  const mainAdvantages = [
    "It is very easy to start making money",
    "We took care of everything",
    "We give you",
  ];

  const dopAdvantages = [
    {
      icon: imgLock,
      text: "A single referral code that allows you to take into account your earnings from all the visitors you attracted,models",
      type: "lock",
    },
    {
      icon: imgPlayVideo,
      text: "Promo tools / creatives. Banners, video and (FRAME with online broadcasters)",
      type: "video",
    },
    {
      icon: imCursorCLick,
      text: "White label allows you to start a adult cams site on your own domain in 2 clicks",
      type: "cursorClick",
    },
    {
      icon: imgTrafficUp,
      text: "Traffic Analytics and Statistics Tools",
      type: "traffic",
    },
    {
      icon: imgUser,
      text: "A personal manager with whom you will always be in touch. Help you make big money!",
      type: "user",
    },
  ];

  return (
    <div className="home__advantages">
      <div className="home__advantages__content">
        <div className="home__advantages__info">
          <div className="home__advantages__list">
            <ul className="home__advantages__list__main">
              {mainAdvantages.map((el, indx) => (
                <li key={indx}>
                  <span>
                    <img src={imgCheck} alt="check" />
                    {t(el)}
                  </span>
                  {indx === mainAdvantages.length - 1 && (
                    <ul className="home__advantages__list__dop">
                      {dopAdvantages.map((dop) => (
                        <li key={dop.type}>
                          <span>
                            <img src={dop.icon} alt={dop.type} /> <p> {t(dop.text)}</p>
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="home__advantages__payment">
            <h2>{t("Payment once a week on Friday")}</h2>
            <div className="home__advantages__payment__currencies">
              <div className="home__advantages__payment__currencies__crypto">
                <img src={imgBitcoin} alt="crypto" />
                <h3>{t("CRYPTO")}</h3>
                <h4>{t("(USDT, BITCOIN, ETH. etc)")}</h4>
              </div>
              <div className="home__advantages__payment__currencies__crypto">
                <img src={imgDollar} alt="flat" />
                <h3>{t("FIAT")}</h3>
                <h4>{t("(USD. EUR. GBP) PAXUM, WIRE, etc")}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="home__advantages__ask">
          <span className="home__advantages__ask__title">{t("Have any questions?")} </span>
          <span className="home__advantages__ask__actions">
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://t.me/camsOfferBot"}
              className="action"
            >
              <img alt="tg" src={imgTg} /> @camsOfferBot
            </a>
            {t("or")}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto: info@camsoffer.com"
              className="action"
            >
              <img alt="mail" src={imgMail} /> info@camsoffer.com
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
