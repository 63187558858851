import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./style/vars.scss";
import "./style/base.scss";
import "./style/materials/materials.scss";
import ConfirmEmail from "./pages/ConfirmEmail";
import useCommonHooks from "./hooks/useCommonHooks";

function App() {
  useCommonHooks();

  return (
    <Router basename="/">
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Routes>
        <Route path={"/confirm/email"} element={<ConfirmEmail />}></Route>
        <Route path="/*" element={<Home />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
