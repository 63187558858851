import React, { useState } from "react";
import imgEye from "../../images/icon/eye.svg";
import imgEyeOff from "../../images/icon/eye-off.svg";
import { useTranslation } from "react-i18next";

export default function CusInput({
  text,
  type,
  name,
  icon,
  grayWarning,
  dopOptions,
  required,
  onChange,
}) {
  const { t } = useTranslation();
  const [showPas, setShowPas] = useState(false);
  const typeInput = type == "password" && showPas ? "text" : type;

  return (
    <div className="custom-input">
      <img src={icon} alt="" />
      <input
        name={name}
        onChange={onChange}
        required={required}
        type={typeInput}
        placeholder={t(text)}
        className="custom-input__input"
        {...dopOptions}
      />
      {grayWarning && <span className="custom-input__gray-warning">{t(grayWarning)}</span>}
      {type === "password" && (
        <button
          type="button"
          className="password__hide__btn"
          onClick={(e) => {
            e.preventDefault();
            setShowPas((prev) => !prev);
          }}
        >
          <img
            className="pas-icon"
            src={!showPas ? imgEye : imgEyeOff}
            alt={showPas ? "hide" : "show"}
          />
        </button>
      )}
    </div>
  );
}
